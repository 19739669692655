import React, { FC, useContext, useState } from 'react';
import {
  DatePicker,
  DayOfWeek,
  Fabric,
  FontWeights,
  getTheme,
  IButtonStyles,
  IconButton,
  IDatePickerStrings,
  ITheme,
  mergeStyleSets,
  PrimaryButton,
  Stack,
  Text,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import oh from 'output-helpers';
import moment from 'moment';

import text from '../text';
import ValidationLog from './ValidationLog';
import {
  isActivationDateChangeable,
  isDateValidActivation,
} from '../verification_functions/verification_functions';
import ConfigContext from '../config';
import createRebusStorageApi from '../api/rebus_storage';

interface DetailsProps {
  rebus_export: RebusExport;
  closeModal: () => void;
  onSaved: (saved_export: RebusExport) => void;
  custom_deadline_date?: Date;
}
const theme = getTheme();

const contentStyles = mergeStyleSets({
  header: [
    theme.fonts.large,
    {
      flex: '1 1 auto',
      display: 'flex',
      minWidth: '500px',
      alignItems: 'center',
      padding: '12px 12px 14px 24px',
      color: theme.semanticColors.bodySubtext,
    },
  ],
  header_bold: {
    fontWeight: FontWeights.semibold,
    color: theme.semanticColors.bodyText,
  },
  meta_value: [
    {
      color: theme.semanticColors.bodySubtext,
      fontWeight: FontWeights.bold,
    },
  ],
  meta_heading: [
    theme.fonts.small,
    {
      color: theme.semanticColors.bodySubtext,
    },
  ],
});

const iconButtonStyles = (theme: ITheme): IButtonStyles => ({
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
});

const formatDateSpan = (a: Date, b: Date): string => {
  return (
    oh.formatDateAsString(a, 'YYYY-MM-DD') +
    ' - ' +
    oh.formatDateAsString(b, 'YYYY-MM-DD')
  );
};

const RebusExportsList: FC<DetailsProps> = ({
  rebus_export,
  closeModal,
  onSaved,
}) => {
  const config = useContext(ConfigContext);
  const activation_date = rebus_export.activation_at;
  const enable_activation_change = isActivationDateChangeable(
    config.deadline_offset,
    rebus_export,
  );
  const [picked_date, setPickedDate] = useState<Date | undefined>(
    activation_date,
  );
  const [saving, setSaving] = useState<boolean>(false);
  let min_date: Date | undefined = moment().add(1, 'day').toDate();
  let start_date: Date | undefined;
  let max_date: Date | undefined;
  if (rebus_export.complete) {
    if (min_date < rebus_export.starts_at) {
      //Tomorrow is less than starts_at, so starts_at is the earliest possible.
      min_date = rebus_export.starts_at;
    } else if (
      !isDateValidActivation(config.deadline_offset, rebus_export, min_date)
    ) {
      //Tomorrow is not valid, two days forward is required.
      min_date = new Date(new Date().valueOf() + 86400000 * 2);
    }
    start_date = rebus_export.starts_at;
    max_date = rebus_export.ends_at;
  }

  if (!rebus_export) {
    //This early out is necessary because the component stays
    //mounted even when the modal is hidden!
    return null;
  }

  const activation_text = rebus_export.activation_at
    ? oh.formatDateAsString(rebus_export.activation_at, 'YYYY-MM-DD')
    : text('no_date_selected');

  const date_locales = oh.getDateLocale();
  const picker_strings: IDatePickerStrings = {
    months: date_locales.months as string[],
    shortMonths: date_locales.monthsShort as string[],
    days: date_locales.weekdays as string[],
    shortDays: date_locales.weekdaysMin as string[],
    goToToday: '',
  };

  return (
    <Fabric>
      <div style={{ width: '1000px', height: '700px' }}>
        <div className={contentStyles.header}>
          <span>{text('details_for_export_uploaded_at')}&nbsp;</span>
          <span className={contentStyles.header_bold}>
            {oh.formatDateAsString(rebus_export.created_at)}
          </span>
          <IconButton
            styles={iconButtonStyles(theme)}
            iconProps={{ iconName: 'Cancel' }}
            onClick={() => closeModal()}
          />
        </div>
        <div>
          <Stack
            horizontal
            style={{ padding: '20px' }}
            horizontalAlign="space-between"
          >
            <Stack.Item>
              <Text className={contentStyles.meta_heading} block>
                {text('status')}
              </Text>
              <Text className={contentStyles.meta_value}>
                {text(rebus_export.state)}
              </Text>
            </Stack.Item>
            <Stack.Item>
              <Text className={contentStyles.meta_heading} block>
                {text('contains_data_for')}
              </Text>
              <Text className={contentStyles.meta_value}>
                {start_date && max_date
                  ? formatDateSpan(start_date, max_date)
                  : '-'}
              </Text>
            </Stack.Item>
            <Stack.Item>
              <Text className={contentStyles.meta_heading} block>
                {text('activation_date')}
              </Text>
              <Text className={contentStyles.meta_value}>
                {activation_text}
              </Text>
            </Stack.Item>
          </Stack>
          <Stack
            horizontal
            style={{
              padding: '20px',
            }}
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Stack.Item
              align="center"
              verticalFill
              grow={false}
              shrink={false}
              styles={{ root: { marginRight: '10px' } }}
            >
              {text('activation_date')}
            </Stack.Item>
            <Stack.Item grow>
              <DatePicker
                data-testid="activation_date_picker"
                disabled={!enable_activation_change || !min_date || !max_date}
                firstDayOfWeek={DayOfWeek.Monday}
                strings={picker_strings}
                minDate={min_date}
                maxDate={max_date}
                value={picked_date}
                placeholder={text('choose_date')}
                formatDate={(d) =>
                  d ? oh.formatDateAsString(d, 'YYYY-MM-DD') : ''
                }
                showGoToToday={false}
                showWeekNumbers={true}
                initialPickerDate={picked_date || min_date}
                onSelectDate={(d) => setPickedDate(d || undefined)}
              />
            </Stack.Item>
            <Stack.Item align="center" grow={false} shrink={false}>
              <div style={{ width: '120px', textAlign: 'right' }}>
                {saving && (
                  <Spinner
                    label={text('saving')}
                    labelPosition="right"
                    size={SpinnerSize.medium}
                  />
                )}
                {!saving && (
                  <PrimaryButton
                    text={text('save')}
                    disabled={
                      !enable_activation_change ||
                      !picked_date ||
                      (activation_date &&
                        picked_date.toISOString() ===
                          activation_date.toISOString())
                    }
                    onClick={() => {
                      if (!picked_date) {
                        return false;
                      }
                      setSaving(true);
                      const api = createRebusStorageApi(config);
                      api
                        .patchDate(rebus_export.id, picked_date)
                        .then((saved_re) => onSaved(saved_re))
                        .catch(() => {
                          console.error('Unable to save modified date.');
                          //TODO: How do we let the user know something went wrong?
                        })
                        .finally(() => {
                          setSaving(false);
                        });
                    }}
                  />
                )}
              </div>
            </Stack.Item>
          </Stack>

          {rebus_export.validation_log && (
            <ValidationLog validation_log={rebus_export.validation_log} />
          )}
        </div>
      </div>
    </Fabric>
  );
};

export default RebusExportsList;
