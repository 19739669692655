import oh, { TypedDictionary } from 'output-helpers';

export type TextKeys =
  | 'activation_date'
  | 'analyzing'
  | 'cancel'
  | 'choose_file'
  | 'choose_date'
  | 'contains_data_for'
  | 'data_for'
  | 'details'
  | 'details_for_export_uploaded_at'
  | 'dispatched'
  | 'download'
  | 'error'
  | 'file'
  | 'invalid'
  | 'last_date_with_data'
  | 'name'
  | 'no_date_selected'
  | 'no_file_selected'
  | 'no_valid_exports_uploaded_yet'
  | 'rebus_export_list'
  | 'received'
  | 'save'
  | 'saving'
  | 'scheduled'
  | 'status'
  | 'upload_in_progress'
  | 'upload_info_1'
  | 'upload_info_2'
  | 'upload'
  | 'uploaded_at'
  | 'valid'
  | 'waiting_for_exports_to_load';

const dictionary: TypedDictionary<Dictionary> = {
  'sv-SE': {
    activation_date: 'aktiveringsdatum',
    analyzing: 'analyserar',
    cancel: 'avbryt',
    choose_date: 'välj datum',
    choose_file: 'välj fil',
    contains_data_for: 'innehåller data för',
    data_for: 'data för',
    details: 'detaljer',
    details_for_export_uploaded_at: 'detaljer för rebus export uppladdad',
    dispatched: 'utskickad',
    download: 'ladda ner',
    error: 'fel',
    file: 'fil',
    invalid: 'ogiltig',
    last_date_with_data: 'sista datumet med data',
    name: 'namn',
    no_date_selected: 'inget datum valt',
    no_file_selected: 'ingen fil vald',
    no_valid_exports_uploaded_yet:
      'inga giltiga eller schemalagda rebus exporter uppladdade',
    rebus_export_list: 'uppladdade rebus exporter',
    received: 'mottagen',
    save: 'spara',
    saving: 'sparar',
    scheduled: 'schemalagd',
    status: 'status',
    upload_in_progress: 'filen laddas upp',
    upload_info_1:
      'Välj fil till höger och klicka på Ladda upp. När filen har laddats upp kommer den att analyseras.',
    upload_info_2:
      'När en fil har analyserats färdigt och är giltig går det att välja dess aktiveringsdatum genom att klicka på Detalj-knappen i tabellen.',
    upload: 'ladda upp',
    uploaded_at: 'uppladdningsdatum',
    valid: 'giltig',
    waiting_for_exports_to_load: 'väntar på att data ska laddas...',
  },
  'en-US': {
    activation_date: 'activation date',
    analyzing: 'analyzing',
    cancel: 'cancel',
    choose_date: 'choose date',
    choose_file: 'choose file',
    contains_data_for: 'contains data for',
    data_for: 'data for',
    details: 'details',
    details_for_export_uploaded_at: 'details for rebus export uploaded at',
    dispatched: 'dispatched',
    error: 'error',
    download: 'download',
    file: 'file',
    invalid: 'invalid',
    last_date_with_data: 'last date with data',
    name: 'name',
    no_date_selected: 'no date selected',
    no_file_selected: 'no file selected',
    no_valid_exports_uploaded_yet: 'no valid rebus exports uploaded yet',
    rebus_export_list: 'uploaded rebus exports',
    received: 'received',
    save: 'save',
    saving: 'saving',
    scheduled: 'scheduled',
    status: 'status',
    upload_in_progress: 'upload in progress',
    upload_info_1:
      'Choose file to the right and click Upload. When the file has been uploaded it will be analyzed.',
    upload_info_2:
      'When analysis is finished and the result is valid it is possible to set its activation date by clicking the Detail-button in the table.',
    upload: 'upload',
    uploaded_at: 'uploaded at',
    valid: 'valid',
    waiting_for_exports_to_load: 'waiting for data to load ...',
  },
};

const translate = (key: keyof Dictionary, capitalize = true): string => {
  return String(
    oh.translateTyped<Dictionary>(
      key,
      capitalize,
      undefined,
      undefined,
      dictionary,
    ),
  );
};

export type Dictionary = { [key in TextKeys]: string };
export { dictionary, translate };
