import React from 'react';
import ReactDOM from 'react-dom';
import WithConfig from '@allbin/with-config';
import oh from 'output-helpers';
import { initializeIcons } from '@uifabric/icons';

import ConfigContext, { default_config } from './config';
import Auth from './Auth';
import ConfigSetters from './ConfigSetters';
import { BrowserRouter as Router } from 'react-router-dom';
import MainView from './MainView';

initializeIcons();

//This config is overwritten in ConfigSetters once the actual config is loaded.
oh.setConfig({
  lang: default_config.default_language,
});

ReactDOM.render(
  <Router>
    <WithConfig Context={ConfigContext} default_config={default_config}>
      <ConfigSetters>
        <Auth>
          <MainView />
        </Auth>
      </ConfigSetters>
    </WithConfig>
  </Router>,
  document.getElementById('root'),
);
