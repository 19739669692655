import React, { FC, useRef, useState } from 'react';
import {
  DefaultButton,
  PrimaryButton,
  Fabric,
  Stack,
  Text,
  ITextStyles,
  Spinner,
  SpinnerSize,
  getTheme,
  ITheme,
} from '@fluentui/react';

import text from '../text';

interface UploadFormProps {
  triggerUpload: (rebus_export: File) => void;
  upload_in_progress: boolean;
}

const triggerUploadDialog = (ref: React.MutableRefObject<any>): void => {
  // eslint-disable-next-line
  ref && ref.current && ref.current.click && ref.current.click();
};

const textStyles = (theme: ITheme): ITextStyles => ({
  root: {
    margin: theme.spacing.l2,
    marginLeft: '0',
    whiteSpace: 'pre-line',
    color: theme.semanticColors.bodySubtext,
  },
});
const UploadForm: FC<UploadFormProps> = ({
  triggerUpload,
  upload_in_progress,
}) => {
  //Not sure what type <input type="file"/> is.
  const inputFileRef = useRef<any>(null);
  const [file, setFile] = useState<File | null>(null);
  const theme = getTheme();

  return (
    <Fabric>
      <input
        type="file"
        ref={inputFileRef}
        style={{ display: 'none' }}
        onChange={(e) => {
          const file = e.target.files && e.target.files.item(0);
          file ? setFile(file) : setFile(null);
        }}
      />
      <Stack horizontal style={{ padding: '20px' }} horizontalAlign="center">
        <Stack.Item>
          <Text block styles={textStyles(theme)} variant="medium">
            {text('upload_info_1') + '\n\n' + text('upload_info_2')}
          </Text>
        </Stack.Item>
        <Stack.Item
          align="stretch"
          grow={false}
          styles={{
            root: {
              borderRight: '1px solid ' + theme.palette.themeTertiary,
              marginRight: '10px',
            },
          }}
        >
          &nbsp;
        </Stack.Item>
        <Stack.Item align="stretch" disableShrink grow>
          {upload_in_progress && (
            <Stack verticalAlign="center" verticalFill>
              <Stack.Item align="center">
                <Spinner
                  label={text('upload_in_progress')}
                  labelPosition="right"
                  size={SpinnerSize.large}
                />
              </Stack.Item>
            </Stack>
          )}
          {!upload_in_progress && (
            <Stack
              verticalAlign="space-evenly"
              verticalFill
              horizontalAlign="center"
            >
              <Stack.Item>
                <Text block>
                  {file
                    ? text('file') + ': ' + file.name
                    : text('no_file_selected') + '...'}
                </Text>
              </Stack.Item>
              <Stack horizontal>
                <Stack.Item>
                  <DefaultButton
                    text={text('choose_file')}
                    iconProps={{ iconName: 'DocLibrary' }}
                    onClick={() => triggerUploadDialog(inputFileRef)}
                    style={{ marginRight: '5px' }}
                  />
                </Stack.Item>
                <Stack.Item>
                  <PrimaryButton
                    text={text('upload')}
                    disabled={!file}
                    iconProps={{ iconName: 'CloudUpload' }}
                    onClick={() => {
                      file && triggerUpload(file);
                      setFile(null);
                    }}
                  />
                </Stack.Item>
              </Stack>
            </Stack>
          )}
        </Stack.Item>
      </Stack>
    </Fabric>
  );
};

export default UploadForm;
