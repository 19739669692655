import moment from 'moment';

/**
  - Now at least D hours before RE.ends_at
  - RE.state one of valid, scheduled
  - If RE.activation_at; it must be at least D hours from Now
*/
export const isActivationDateChangeable = (
  deadline_offset: number,
  rebus_export: RebusExport,
): boolean => {
  if (rebus_export.state !== 'valid' && rebus_export.state !== 'scheduled') {
    return false;
  }
  const re = rebus_export;

  const now = moment.utc(new Date());

  //Check that _now_ is more than deadline hours before the export ends.
  if (moment.utc(re.ends_at).subtract(deadline_offset, 'hours').isBefore(now)) {
    return false;
  }

  //Check that _activation_at_ is in deadline hours away from now.
  if (
    re.activation_at &&
    now.add(deadline_offset, 'hours').isAfter(moment.utc(re.activation_at))
  ) {
    return false;
  }

  return true;
};

/**
 - RE.state one of valid, scheduled
 - RE.starts_at before or equal to date_to_check
 - RE.ends_at after or equal to date_to_check
 - date_to_check at least deadline_offset hours from NOW
*/
export const isDateValidActivation = (
  deadline_offset: number,
  rebus_export: RebusExport,
  date_to_check: Date,
): boolean => {
  if (rebus_export.state !== 'valid' && rebus_export.state !== 'scheduled') {
    return false;
  }
  const date = moment.utc(date_to_check).add(1, 'ms');

  const re = rebus_export;

  if (!moment.utc(re.starts_at).isBefore(date)) {
    return false;
  }

  if (!moment.utc(re.ends_at).isAfter(date)) {
    return false;
  }

  const now = moment.utc(new Date());
  if (now.add(deadline_offset, 'hours').isAfter(date)) {
    return false;
  }

  return true;
};

export const hasExportsChanged = (
  a: RebusExport[],
  b: RebusExport[],
): boolean => {
  const check_a = a.some((a_re) => {
    const b_re = b.find((re) => re.id === a_re.id);
    if (!b_re || b_re.modified_at !== a_re.modified_at) {
      return true;
    }
    return false;
  });
  if (check_a) {
    return true;
  }
  const check_b = b.some((b_re) => {
    const a_re = a.find((re) => re.id === b_re.id);
    if (!a_re || a_re.modified_at !== b_re.modified_at) {
      return true;
    }
    return false;
  });
  if (check_b) {
    return true;
  }
  return false;
};
