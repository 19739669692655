import React, { FC, useState } from 'react';
import {
  getTheme,
  mergeStyleSets,
  Fabric,
  List,
  FontIcon,
  Stack,
} from '@fluentui/react';

interface ValidationLogProps {
  validation_log: ValidationLog;
}

const theme = getTheme();

const { palette, semanticColors, fonts } = theme;

const listStyles = mergeStyleSets({
  itemCell: {
    padding: '5px 10px',
    boxSizing: 'border-box',
    borderBottom: `1px solid ${semanticColors.bodyDivider}`,
    display: 'flex',
    selectors: {
      '&:hover': { background: palette.neutralLight },
    },
    fontSize: fonts.smallPlus.fontSize,
  },
  itemContent: {
    marginLeft: 10,
    overflow: 'hidden',
    flexGrow: 1,
  },
  itemIndex: {
    fontSize: fonts.small.fontSize,
    color: palette.neutralTertiary,
    marginRight: '10px',
  },
});

const PassIcon = (
  <FontIcon
    iconName="BoxCheckmarkSolid"
    style={{ color: 'green', fontSize: fonts.large.fontSize }}
  />
);
const FailIcon = (
  <FontIcon
    iconName="StatusErrorFull"
    style={{ color: 'red', fontSize: fonts.large.fontSize }}
  />
);

interface CellProps {
  verdict: Verdict;
  index: number;
}

const Cell: FC<CellProps> = ({ verdict, index }) => {
  const [show_messages, setShowMessages] = useState<boolean>(false);
  const msgs_available = verdict.messages && verdict.messages.length > 0;
  return (
    <div className={listStyles.itemCell} data-is-focusable={true}>
      {verdict.pass ? PassIcon : FailIcon}
      <div
        className={listStyles.itemContent}
        onClick={() => msgs_available && setShowMessages(!show_messages)}
      >
        <Stack horizontal horizontalAlign="space-between">
          <Stack.Item grow>
            <span className={listStyles.itemIndex}>{index}</span>
            {verdict.statement}
          </Stack.Item>
          {msgs_available && (
            <Stack.Item grow={false} shrink={false}>
              <FontIcon
                iconName={show_messages ? 'ChevronUpSmall' : 'ChevronDownSmall'}
              />
            </Stack.Item>
          )}
        </Stack>
        {show_messages && verdict.messages && (
          <div>
            {verdict.messages.map((msg, i) => (
              <div key={i}>- {msg}</div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const renderCell = (verdict?: Verdict, index?: number): JSX.Element | null => {
  if (!verdict || typeof index !== 'number') {
    return null;
  }
  return <Cell verdict={verdict} index={index} />;
};

const ValidationLog: FC<ValidationLogProps> = ({ validation_log }) => {
  return (
    <Fabric>
      <List items={validation_log.verdicts} onRenderCell={renderCell} />
    </Fabric>
  );
};

export default ValidationLog;
