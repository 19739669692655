/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useHistory } from 'react-router';
import SSO from 'sso-helper';

import ConfigContext from './config';

declare global {
  interface Window {
    sso: any;
  }
}

const AuthCheck: React.FC = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const config = useContext(ConfigContext);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (window.sso) {
      return;
    }
    const loginUri =
      config.api.sso.login_uri +
      '/login' +
      '?service=' +
      encodeURIComponent(config.service_name);

    const ssoConfig = {
      login_uri: config.api.sso.login_uri,
      token_provider_uri: config.api.sso.uri,
      jwt_acquire_callback: () => {
        console.log(
          '[JWT] acquire callback called, redirecting to',
          location.pathname,
        );
        history.push(location.pathname);
        setLoggedIn(true);
      },
      jwt_renew_callback: () => {
        // console.log(`[JWT] new token received`);
      },
      jwt_release_callback: (wasLogout: boolean) => {
        window.sso = null;
        if (!wasLogout) {
          console.log('navigating to login', loginUri);
          document.location.href = loginUri;
        }
      },
    };

    window.sso = new SSO(config.service_name, ssoConfig);
    window.sso
      .init()
      .then(() => {
        if (!window.sso.isLoggedIn()) {
          console.log('navigating to login', loginUri);
          document.location.href = loginUri;
          return;
        }
      })
      .catch((err: Error) => {
        console.error('error in sso init', err);
        window.sso.logout();
      })
      .then(() => {
        setLoggedIn(true);
      });
  }, [history, location.pathname, config]);

  if (!loggedIn) {
    return <div>Redirecting...</div>;
  }

  return <div>{children}</div>;
};

export default AuthCheck;
