import React, { FC, useContext } from 'react';
import {
  DetailsList,
  Fabric,
  IColumn,
  FontIcon,
  mergeStyleSets,
  SelectionMode,
  getTheme,
} from '@fluentui/react';
import oh from 'output-helpers';

import text from '../text';
import { isActivationDateChangeable } from '../verification_functions/verification_functions';
import ConfigContext from '../config';
import { formatActivationAt } from '../api/rebus_storage';

const theme = getTheme();
const size = {
  fontSize: '18px',
};
const colors = mergeStyleSets({
  orange: [{ color: 'orange' }, size],
  red: [{ color: 'red' }, size],
  green: [{ color: 'green' }, size],
});

const icon_by_status: Record<ExportState, React.ReactElement> = {
  received: <FontIcon iconName="AwayStatus" className={colors.orange} />,
  analyzing: <FontIcon iconName="Processing" className={colors.orange} />,
  invalid: <FontIcon iconName="StatusErrorFull" className={colors.red} />,
  error: <FontIcon iconName="WarningSolid" className={colors.red} />,
  scheduled: <FontIcon iconName="BoxCheckmarkSolid" className={colors.green} />,
  valid: <FontIcon iconName="BoxCheckmarkSolid" className={colors.green} />,
  dispatched: (
    <FontIcon iconName="BoxCheckmarkSolid" className={colors.green} />
  ),
};

const formatDateSpan = (a: Date, b: Date): string => {
  return (
    oh.formatDateAsString(a, 'YYYY-MM-DD') +
    ' - ' +
    oh.formatDateAsString(b, 'YYYY-MM-DD')
  );
};

interface RebusExportsListProps {
  rebus_exports: RebusExport[];
  setSelectedId: (id: number) => void;
  triggerDownload: (rebus_export: RebusExportComplete) => void;
}

const RebusExportsList: FC<RebusExportsListProps> = ({
  rebus_exports,
  setSelectedId,
  triggerDownload,
}) => {
  const config = useContext(ConfigContext);
  const columns: IColumn[] = [
    {
      key: 'state_icon',
      minWidth: 30,
      maxWidth: 30,
      isResizable: false,
      name: '',
      onRender: (rebus_export: RebusExport) =>
        icon_by_status[rebus_export.state],
    },
    {
      key: 'state_text',
      minWidth: 60,
      maxWidth: 120,
      isCollapsible: true,
      name: text('status'),
      onRender: (rebus_export: RebusExport) => text(rebus_export.state),
    },
    {
      key: 'created_at',
      name: text('uploaded_at'),
      minWidth: 150,
      isResizable: true,
      onRender: (rebus_export: RebusExport) =>
        oh.formatDateAsString(rebus_export.created_at),
    },
    {
      key: 'data_for',
      name: text('contains_data_for'),
      minWidth: 170,
      isCollapsible: true,
      isResizable: true,
      onRender: (rebus_export: RebusExport) =>
        rebus_export.complete
          ? formatDateSpan(rebus_export.starts_at, rebus_export.ends_at)
          : '-',
    },
    {
      key: 'activation_at',
      name: text('activation_date'),
      minWidth: 150,
      isResizable: true,
      onRender: (rebus_export: RebusExport) => {
        if (isActivationDateChangeable(config.deadline_offset, rebus_export)) {
          return (
            <div
              style={{ color: theme.palette.themePrimary, cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedId(rebus_export.id);
              }}
            >
              {rebus_export.activation_at
                ? formatActivationAt(rebus_export.activation_at)
                : text('choose_date')}
            </div>
          );
        }
        return (
          <div>
            {rebus_export.activation_at
              ? formatActivationAt(rebus_export.activation_at)
              : '-'}
          </div>
        );
      },
    },
    {
      key: 'more',
      name: text('details'),
      minWidth: 50,
      isResizable: false,
      onRender: (rebus_export: RebusExport) => {
        if (rebus_export.state === 'analyzing') {
          return null;
        }
        return (
          <FontIcon
            iconName="More"
            style={{
              cursor: 'pointer',
              color: theme.palette.themePrimary,
              fontSize: '18px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedId(rebus_export.id);
            }}
          />
        );
      },
    },
    {
      key: 'download',
      name: '',
      minWidth: 30,
      isResizable: false,
      onRender: (rebus_export: RebusExport) =>
        rebus_export.complete ? (
          <FontIcon
            iconName="CloudDownload"
            style={{
              cursor: 'pointer',
              color: theme.palette.themePrimary,
              fontSize: '18px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              triggerDownload(rebus_export);
            }}
          />
        ) : null,
    },
  ];
  return (
    <Fabric>
      <DetailsList
        columns={columns}
        items={rebus_exports}
        selectionMode={SelectionMode.none}
      />
    </Fabric>
  );
};

export default RebusExportsList;
