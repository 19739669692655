import moment from 'moment';

const mock_rebus_exports: RebusExportSerialized[] = [];

const rand = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1) + min);

const states: ExportState[] = [
  'valid',
  'scheduled',
  'dispatched',
  'analyzing',
  'invalid',
  'error',
  'received',
];

const randDateTime = (min_date: Date, max_date: Date): Date => {
  let date = moment(min_date)
    .add(rand(0, 100), 'days')
    .add(rand(0, 20), 'hours');
  while (date.isAfter(max_date)) {
    date = moment(min_date).add(rand(0, 100), 'days').add(rand(0, 20), 'hours');
  }
  return date.toDate();
};

const msgs: string[] = [
  'Stuff n things ...',
  'Wubba Lubba Dub Dub!',
  'Weddings are basically funerals with a cake.',
  'This is a really long error message! This is a really long error message! This is a really long error message! This is a really long error message! This is a really long error message! This is a really long error message!',
];

const randMsg = (): string => {
  return msgs[rand(0, msgs.length - 1)];
};

const generateLog = (state: ExportState): ValidationLogSerialized => {
  const fail = state === 'invalid';
  const start = randDateTime(
    new Date('2020-01-01'),
    new Date(new Date().valueOf() - 100000),
  );
  const end = randDateTime(start, new Date());
  const fail_verdicts: VerdictSerialized[] = [
    {
      pass: false,
      statement: 'Verifying X-files',
      timestamp: end.toISOString(),
      messages: Array(rand(1, 300))
        .fill(null)
        .map(() => randMsg()),
    },
    {
      pass: false,
      statement: 'Checking solar flare activity',
      timestamp: end.toISOString(),
      messages: Array(rand(1, 300))
        .fill(null)
        .map(() => randMsg()),
    },
    {
      pass: false,
      statement: 'Checking thing Z',
      timestamp: end.toISOString(),
      messages: Array(rand(1, 300))
        .fill(null)
        .map(() => randMsg()),
    },
    {
      pass: false,
      statement: 'Checking birthdays',
      timestamp: end.toISOString(),
      messages: Array(rand(1, 300))
        .fill(null)
        .map(() => randMsg()),
    },
    {
      pass: false,
      statement:
        'This is a really long name for a check that failed! This is a really long name for a check that failed!',
      timestamp: end.toISOString(),
      messages: Array(rand(1, 300))
        .fill(null)
        .map(() => randMsg()),
    },
  ];
  const success_verdicts: VerdictSerialized[] = [
    {
      pass: true,
      statement: 'I turned myself into a pickle, Morty! I’m Pickle Ri-i-i-ick!',
      timestamp: end.toISOString(),
    },
    {
      pass: true,
      statement:
        "Honey, stop raising your father's cholesterol so you can take a hot funeral selfie.",
      timestamp: end.toISOString(),
    },
    {
      pass: true,
      statement:
        'What about the reality where Hitler cured cancer, Morty? Very long successful check here. Very long successful check here. Very long successful check here. Very long successful check here.',
      timestamp: end.toISOString(),
    },
  ];
  const verdicts: VerdictSerialized[] = [];
  for (let i = 0; i < rand(2, 5); i++) {
    verdicts.push(success_verdicts[rand(0, success_verdicts.length - 1)]);
  }
  if (fail) {
    for (let i = 0; i < rand(1, 4); i++) {
      verdicts.push(fail_verdicts[rand(0, fail_verdicts.length - 1)]);
    }
  }
  return {
    ended_at: end.toISOString(),
    started_at: start.toISOString(),
    verdicts,
  };
};

const generateExport = (): RebusExportSerialized => {
  const state: ExportState = states[rand(0, states.length - 1)];
  const log: ValidationLogSerialized | undefined =
    state === 'analyzing' ? undefined : generateLog(state);
  const created_at = randDateTime(new Date('2020-05-01'), new Date());
  const re: RebusExportSerialized = {
    id: mock_rebus_exports.length,
    state: state,
    validation_log: log,
    organization: 'allbinary',
    created_at: created_at.toISOString(),
    modified_at: created_at.toISOString(),
    uploader_id: 3,
  };

  if (state === 'valid' || state === 'scheduled' || state === 'dispatched') {
    //Add additional stuff.
    const starts = randDateTime(
      created_at,
      new Date(new Date().valueOf() + rand(0, 20) * 86400000),
    );
    const ends = randDateTime(
      new Date(starts.valueOf() + rand(9, 200) * 86400000),
      new Date(starts.valueOf() + rand(220, 360) * 86400000),
    );

    re.starts_at = moment(starts).format('YYYY-MM-DD');
    re.ends_at = moment(ends).format('YYYY-MM-DD');
    re.sha256_hash = 'sha!hash';
    re.activation_at =
      rand(0, 10) > 5 || state === 'dispatched' || state === 'scheduled'
        ? moment(randDateTime(starts, ends)).format('YYYY-MM-DD')
        : undefined;
    // console.log('state:', re.state);
    // console.log('starts:', re.starts_at);
    // console.log('ends:', re.ends_at);
    // console.log('----');
  }

  return re;
};

export const init = (min = 6, max = 25): void => {
  if (mock_rebus_exports.length < 1) {
    //Generate initial batch of exports.
    Array(rand(min, max))
      .fill(null)
      .forEach(() => {
        mock_rebus_exports.push(generateExport());
      });
  }
};

export const getExports = (): Promise<RebusExportSerialized[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mock_rebus_exports);
    }, rand(300, 1500));
  });
};

export const addExport = (): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      mock_rebus_exports.push(generateExport());
      resolve();
    }, rand(1000, 10000));
  });
};

export const updateActivation = (
  id: number,
  d: Date,
): Promise<RebusExportSerialized> => {
  return new Promise<RebusExportSerialized>((resolve, reject) => {
    setTimeout(() => {
      const re = mock_rebus_exports.find((re) => re.id === id);
      if (!re) {
        reject();
        return;
      }
      re.activation_at = moment(d).format('YYYY-MM-DD');
      resolve(re);
    }, rand(300, 2000));
  });
};
