import fileSaver from 'file-saver';
import oh from 'output-helpers';
import apiCall from './api_call';
import {
  deserializeRebusExports,
  deserializeRebusExport,
} from './serialization';
import {
  getExports,
  addExport,
  updateActivation,
  init,
} from './mock_functions';

interface RebusStorageApi {
  get: () => Promise<RebusExport[]>;
  postRebusExport: (rebus_export: File) => Promise<void>;
  patchDate: (rebus_export_id: number, new_date: Date) => Promise<RebusExport>;
  download: (rebus_export: RebusExport) => Promise<void>;
}

export const formatActivationAt = (date: Date): string => {
  const y = date.getFullYear().toString();
  const m = (date.getMonth() + 1).toString();
  const d = date.getDate().toString();

  return `${y}-${m.length < 2 ? '0' + m : m}-${d.length < 2 ? '0' + d : d}`;
};

const createRebusStorageApi = (config: Config): RebusStorageApi => {
  if (config.mock) {
    init();
  }
  return {
    get: () => {
      const req = config.mock
        ? getExports()
        : apiCall<RebusExportSerialized[]>(
            config.api.rebus_storage.uri + '/rebus_exports',
          );

      return req.then((res) => {
        return deserializeRebusExports(res).sort(
          (a, b) => b.created_at.valueOf() - a.created_at.valueOf(),
        );
      });
    },
    postRebusExport: (rebus_export) => {
      const req = config.mock
        ? addExport()
        : apiCall(
            config.api.rebus_storage.uri + '/rebus_exports',
            'POST',
            undefined,
            rebus_export,
            {
              'Content-Type': 'application/zip',
            },
          );
      return req;
    },
    patchDate: (rebus_export_id, new_date) => {
      const req = config.mock
        ? updateActivation(rebus_export_id, new_date)
        : apiCall<RebusExportSerialized>(
            config.api.rebus_storage.uri + '/rebus_exports/' + rebus_export_id,
            'PATCH',
            undefined,
            {
              activation_at: formatActivationAt(new_date),
            },
          );
      return req.then((re_serialized) => {
        const re = deserializeRebusExport(re_serialized);
        if (!re) {
          throw new Error(
            'Malformed export recieved from server when PATCHing',
          );
        }
        return re;
      });
    },
    download: (rebus_export) => {
      if (config.mock) {
        console.log('Download triggered, mocking discarded trigger.');
        return Promise.resolve();
      }
      const req = apiCall<Blob>(
        config.api.rebus_storage.uri +
          '/rebus_exports/' +
          rebus_export.id +
          '/download',
        'GET',
        undefined,
        undefined,
        undefined,
        'blob',
      );

      return req.then((res) =>
        fileSaver.saveAs(
          res,
          'rebus export ' +
            oh.formatDateAsString(rebus_export.created_at) +
            '.zip',
        ),
      );
    },
  };
};

export default createRebusStorageApi;
