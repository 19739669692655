import React, { FC } from 'react';
import {
  Text,
  getTheme,
  mergeStyleSets,
  FontWeights,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import oh from 'output-helpers';

import text from '../text';

interface LastDateWithDataProps {
  rebus_exports: RebusExport[];
  loading: boolean;
}
const theme = getTheme();

const styles = mergeStyleSets({
  container: {
    display: 'flex',
    marginLeft: '10px',
    padding: '0 20px 0 20px',
    backgroundColor: theme.palette.themeSecondary,
    height: '100%',
    width: '220px',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    color: 'white',
  },
  heading: [
    theme.fonts.mediumPlus,
    {
      fontWeight: FontWeights.semibold,
    },
  ],
  value: [
    theme.fonts.large,
    {
      fontWeight: FontWeights.bold,
    },
  ],
  info_value: [
    theme.fonts.medium,
    {
      fontWeight: FontWeights.regular,
    },
  ],
});

const LastDateWithData: FC<LastDateWithDataProps> = ({
  rebus_exports,
  loading,
}) => {
  let last_date: Date | undefined;
  rebus_exports.forEach((re) => {
    if (re.state === 'scheduled' || re.state === 'dispatched') {
      last_date = !last_date || re.ends_at > last_date ? re.ends_at : last_date;
    }
  });

  return (
    <div className={styles.container}>
      <Text block className={styles.heading}>
        {text('last_date_with_data')}
      </Text>
      {loading && <Spinner size={SpinnerSize.medium} />}
      {!loading && !last_date && (
        <Text className={styles.info_value}>
          {text('no_valid_exports_uploaded_yet')}
        </Text>
      )}
      {!loading && last_date && (
        <Text className={styles.value}>
          {oh.formatDateAsString(last_date, 'YYYY-MM-DD')}
        </Text>
      )}
    </div>
  );
};

export default LastDateWithData;
