import React, { FC, useContext, useEffect, useState } from 'react';

import RebusExportsList from './RebusExportsList';
import UploadForm from './UploadForm';
import { mergeStyleSets, Modal, Separator, Stack } from '@fluentui/react';

import text from './text';
import Details from './Details';
import createRebusStorageApi from './api/rebus_storage';
import ConfigContext from './config';
import { hasExportsChanged } from './verification_functions/verification_functions';
import LastDateWithData from './LastDateWithData';

const modalStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
});

const updateExport = (
  exports: RebusExport[],
  updated_export: RebusExport,
): RebusExport[] => {
  const existing = exports.findIndex((re) => updated_export.id === re.id);
  if (existing < 0) {
    return exports;
  }
  const copy = [...exports];
  copy[existing] = updated_export;
  return copy;
};

const doUpload = (
  config: Config,
  setUploading: (val: boolean) => void,
  file: File,
): void => {
  setUploading(true);
  const api = createRebusStorageApi(config);
  api
    .postRebusExport(file)
    .then(() => {
      setUploading(false);
    })
    .catch((err) => {
      //Upload failed.
      console.error('Upload failed');
      throw err;
    });
};

const MainView: FC = () => {
  const config = useContext(ConfigContext);
  const [initial_loading, setInitialLoading] = useState<boolean>(true);
  const [uploading, setUploading] = useState<boolean>(false);
  const [selected_id, setSelectedId] = useState<number | null>(null);
  const [rebus_exports, setRebusExports] = useState<RebusExport[]>([]);

  useEffect(() => {
    const fetchExports = (): void => {
      const api = createRebusStorageApi(config);
      api
        .get()
        .then((fresh_rebus_exports) => {
          if (hasExportsChanged(rebus_exports, fresh_rebus_exports)) {
            console.log('Changed exports from server found, updating state.');
            setRebusExports(fresh_rebus_exports);
          }
          if (initial_loading) {
            setInitialLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };
    fetchExports();
    const timeout = setInterval(() => {
      fetchExports();
    }, config.update_frequency * 1000);
    return () => {
      clearInterval(timeout);
    };
  }, [config, rebus_exports, uploading, initial_loading]);

  const selected_rebus_export = rebus_exports.find(
    (re) => re.id === selected_id,
  );

  return (
    <div>
      <Modal
        isOpen={!!selected_rebus_export}
        containerClassName={modalStyles.container}
      >
        {selected_rebus_export && (
          <Details
            closeModal={() => setSelectedId(null)}
            rebus_export={selected_rebus_export}
            onSaved={(saved_export) => {
              setRebusExports(updateExport(rebus_exports, saved_export));
            }}
          />
        )}
      </Modal>
      <Stack horizontal>
        <Stack.Item>
          <UploadForm
            upload_in_progress={uploading}
            triggerUpload={(file) => {
              doUpload(config, setUploading, file);
            }}
          />
        </Stack.Item>
        <Stack.Item grow={false} shrink={false} align="stretch">
          <LastDateWithData
            rebus_exports={rebus_exports}
            loading={initial_loading}
          />
        </Stack.Item>
      </Stack>
      <Separator alignContent="center" styles={{ root: { marginTop: '50px' } }}>
        {text('rebus_export_list')}
      </Separator>
      <RebusExportsList
        rebus_exports={rebus_exports}
        triggerDownload={(rebus_export) => {
          void createRebusStorageApi(config).download(rebus_export);
        }}
        setSelectedId={setSelectedId}
      />
    </div>
  );
};

export default MainView;
