import React from 'react';

export const default_config: Config = {
  api: {
    rebus_storage: {
      uri: 'https://rebus-storage.api.test.allbin.se',
    },
    sso: {
      uri: 'https://sso.allbin.se/v2',
      login_uri: 'https://login.allbin.se/v2',
    },
    slack: {
      webhook_uri:
        'https://hooks.slack.com/services/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',
    },
  },
  service_name: 'rebus_storage_test',
  default_language: 'sv-SE',
  production: process.env.NODE_ENV === 'production',
  send_errors_to_slack_in_dev: false,
  deadline_offset: 6,
  update_frequency: 5,
  mock: process.env.NODE_ENV !== 'production',
};

const ConfigContext = React.createContext<Config>(default_config);
export default ConfigContext;
