import React, { FC, useContext, useEffect } from 'react';
import ConfigContext from './config';
import oh from 'output-helpers';

const ConfigSettersAndER: FC = ({ children }) => {
  const config = useContext(ConfigContext);

  useEffect(() => {
    oh.setConfig({
      lang: config.default_language,
    });
  }, [config]);

  return <div>{children}</div>;
};

export default ConfigSettersAndER;
