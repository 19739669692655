import moment from 'moment';

const deserializeValidationLog = (
  serialized_log: ValidationLogSerialized,
): ValidationLog => {
  return {
    started_at: new Date(serialized_log.started_at),
    ended_at: new Date(serialized_log.ended_at),
    verdicts: serialized_log.verdicts.map((serialized_verdict) => {
      return Object.assign({}, serialized_verdict, {
        timestamp: new Date(serialized_verdict.timestamp),
      });
    }),
  };
};

const checkPropTypes = (
  props_and_types: [string, string][],
  obj: { [key: string]: any },
): boolean => {
  return props_and_types.every(([prop, type]) => {
    return typeof obj[prop] === type;
  });
};

export const deserializeRebusExport = (
  serialized_export: RebusExportSerialized,
): RebusExport | false => {
  const incomplete_props = {
    id: 'number',
    state: 'string',
    created_at: 'string',
  };
  const complete_props = Object.assign({}, incomplete_props, {
    sha256_hash: 'string',
    starts_at: 'string',
    ends_at: 'string',
    validation_log: 'object',
  });

  if (
    (serialized_export.state === 'invalid' ||
      serialized_export.state === 'analyzing' ||
      serialized_export.state === 'error' ||
      serialized_export.state === 'received') &&
    checkPropTypes(Object.entries(incomplete_props), serialized_export)
  ) {
    const incomplete: RebusExportIncomplete = {
      id: serialized_export.id,
      state: serialized_export.state,
      complete: false,
      modified_at: serialized_export.modified_at,
      created_at: new Date(serialized_export.created_at),
      activation_at: serialized_export.activation_at
        ? new Date(serialized_export.activation_at)
        : undefined,
      validation_log: serialized_export.validation_log
        ? deserializeValidationLog(serialized_export.validation_log)
        : undefined,
    };
    return incomplete;
  } else if (
    serialized_export.state === 'scheduled' &&
    !serialized_export.activation_at
  ) {
    console.error('Malformed rebus export, discarding.');
    //TODO: Send log to slack!
    return false;
  } else if (
    (serialized_export.state === 'scheduled' ||
      serialized_export.state === 'valid' ||
      serialized_export.state === 'dispatched') &&
    checkPropTypes(Object.entries(complete_props), serialized_export)
  ) {
    const serialized: Required<RebusExportSerialized> = serialized_export as any;
    const complete: RebusExportComplete = {
      id: serialized_export.id,
      state: serialized_export.state,
      complete: true,
      created_at: new Date(serialized.created_at),
      modified_at: serialized.modified_at,
      activation_at: serialized.activation_at
        ? new Date(serialized.activation_at)
        : undefined,
      sha256_hash: serialized.sha256_hash,
      starts_at: moment(serialized.starts_at, 'YYYY-MM-DD').toDate(),
      ends_at: moment(serialized.ends_at, 'YYYY-MM-DD').toDate(),
      validation_log: deserializeValidationLog(serialized.validation_log),
    };
    return complete;
  } else {
    console.error('Malformed rebus export, discarding.');
    //TODO: Send log to slack.
    return false;
  }
};

export const deserializeRebusExports = (
  serialized_exports: RebusExportSerialized[],
): RebusExport[] => {
  const valid_exports: RebusExport[] = [];
  serialized_exports.forEach((serialized) => {
    const deserialized = deserializeRebusExport(serialized);
    if (deserialized) {
      valid_exports.push(deserialized);
    }
  });
  return valid_exports;
};
